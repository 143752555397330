import React from 'react'
import { Layout, UserContext } from '../components/Layout'
import { Grid, Cell, ALIGNMENT } from 'baseui/layout-grid'
import { HeadingLevel } from 'baseui/heading'
import { Heading } from '../components/Heading'
import { WindowLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { getQueryVariable, handleErrorFromAPI, loginRedirect } from '../utils'
import { getToolsData, ToolsPagesResponse, GenericContentStream } from '../api/cms'
import { ourColors } from '../components/Colors'
import { useStyletron } from 'baseui'
import { ParsedGenericContent } from '../components/ParsedGenericContent'
import { ErrorNotification } from '../components/Notification'

interface ToolsPageHeaderProps {
  pageTitle: string
}
const ToolsPageHeader = ({ pageTitle }: ToolsPageHeaderProps) => {
  const [css] = useStyletron()
  return (
    <>
      <Grid align={ALIGNMENT.center}>
        <Cell span={[2.25, 3.5]}>
          <HeadingLevel>
            <Heading data-testid="pageTitle">{pageTitle}</Heading>
          </HeadingLevel>
        </Cell>
      </Grid>
      <Grid>
        <Cell span={[4, 6]}>
          <hr className={css({ background: ourColors.interfaceGrey })} />
        </Cell>
      </Grid>
    </>
  )
}

interface ToolsPageProps {
  location: WindowLocation
}
export const ToolsPage = (props: ToolsPageProps) => {
  const { token, role, setUserContext } = React.useContext(UserContext)
  const [pageTitle, setPageTitle] = React.useState('')
  const toolsId = getQueryVariable('id', props.location.search)
  const [pageContent, setPageContent] = React.useState<GenericContentStream | null>(null)
  const [loadingErrorActive, setLoadingErrorActive] = React.useState(false)

  React.useEffect(() => {
    if (!token) {
      loginRedirect()
    }
    if (!toolsId) {
      navigate('/404', { replace: true })
    }

    if (token && toolsId && role) {
      getToolsData(token, toolsId)
        .then((response: ToolsPagesResponse) => {
          if (response.meta.total_count > 0) {
            const responsePage = response.items[0]
            setPageTitle(responsePage.title)
            if (role === 'HSR') {
              setPageContent(responsePage.hsr_content)
            } else if (role === 'Organiser') {
              setPageContent(responsePage.org_content)
            }
          } else {
            navigate('/404', { replace: true })
          }
        })
        .catch(error => handleErrorFromAPI(error, setUserContext, setLoadingErrorActive))
    }
  }, [token, toolsId, role, setUserContext])

  return (
    <>
      <Grid>
        <Cell span={[4, 6]}>
          <ErrorNotification hasError={loadingErrorActive} />
        </Cell>
      </Grid>
      {pageTitle && <ToolsPageHeader pageTitle={pageTitle} />}
      {role && pageContent && <ParsedGenericContent contentStream={pageContent} role={role} />}
    </>
  )
}
interface LayoutToolsPageProps {
  location: WindowLocation
}
const LayoutToolsPage = (props: LayoutToolsPageProps) => {
  return (
    <Layout>
      <ToolsPage location={props.location} />
    </Layout>
  )
}

export default LayoutToolsPage
