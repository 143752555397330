import { GenericContentStream } from '../api/cms'
import { MainContent } from './MainContent'
import React from 'react'
import { KnowledgeBox } from './KnowledgeBox'
import { Panel, AccordionSection } from './Accordion'
import { RichTextAsElement } from './RichTextAsElement'

interface ParsedGenericContentProps {
  contentStream: GenericContentStream
  role: 'Organiser' | 'HSR'
}
export const ParsedGenericContent = ({ contentStream, role }: ParsedGenericContentProps) => {
  return (
    <>
      {contentStream.map(contentItem => {
        if (contentItem.type === 'text_block') {
          return <MainContent richText={contentItem.value} key={contentItem.id} />
        } else if (contentItem.type === 'know_block') {
          return <KnowledgeBox richText={contentItem.value} key={contentItem.id} role={role} />
        } else if (contentItem.type === 'accordion') {
          return (
            <AccordionSection heading={contentItem.value.heading} key={contentItem.id}>
              {contentItem.value.panels.map(panelItem => {
                return (
                  <Panel title={panelItem.value.heading} key={panelItem.id}>
                    <RichTextAsElement richText={panelItem.value.content} depth={0} forAccordion />
                  </Panel>
                )
              })}
            </AccordionSection>
          )
        }
      })}
    </>
  )
}
